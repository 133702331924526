import React from "react"

const GalleryPage = () => (
  <div className="container centered vertical px-5 pb-5">
    <p className="align-image justify">
      Sedang dalam proses pengerjaan. Terima kasih atas kesabarannya.
    </p>
  </div>
)

export default GalleryPage
